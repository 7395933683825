import React from 'react'

import Button from 'components/Button'

const RefButton = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof Button>>(
	(props, ref) => (
		<div ref={ref}>
			{/* @ts-ignore */}
			<Button noOutline size="medium" {...props} customColor={true} textColor="#FFF">
				{props.children}
			</Button>
		</div>
	)
)

export default RefButton
