import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import TextLogoo from 'assets/png/SFCX-name-logo.png'
import { FlexDivColCentered } from 'components/layout/flex'
import { Body } from 'components/Text'

const PoweredBySynthetix = () => {
	const { t } = useTranslation()
	return (
		<Container>
			<Text>{t('common.powered-by')}</Text>
			{/* <ExternalLink> */}
			<Image src={TextLogoo} alt="text-logo" width={147} height={60} style={{ marginTop: -30 }} />
			{/* </ExternalLink> */}
		</Container>
	)
}

const Container = styled(FlexDivColCentered)`
	row-gap: 15px;
`

const Text = styled(Body)`
	font-size: 10px;
	color: #231f20;
	opacity: 0.5;
	letter-spacing: 0.04em;
	font-variant: small-caps;
	text-transform: uppercase;
	line-height: 100%;
	text-align: center;
	margin-left: 30px;
`

export default PoweredBySynthetix
