import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { FlexDivColCentered } from 'components/layout/flex'
import * as Text from 'components/Text'
import { SmallGoldenHeader, WhiteHeader } from 'styles/common'
import media from 'styles/media'

const TradeNow = () => {
	const { t } = useTranslation()

	return (
		<Container>
			<FlexDivColCentered>
				<TransparentCard>
					<SmallGoldenHeader>{t('homepage.tradenow.title')}</SmallGoldenHeader>
					<BigWhiteHeader>
						The first ever exchange exclusively for commodoties trading
					</BigWhiteHeader>
					<GrayDescription>{t('homepage.tradenow.categories')}</GrayDescription>
					{/* <CTAContainer>
						<Link href={ROUTES.Markets.Home(DEFAULT_FUTURES_MARGIN_TYPE)}>
							<RefButton variant="flat" textColor="yellow" size="medium">
								{t('homepage.nav.trade-now')}
							</RefButton>
						</Link>
					</CTAContainer> */}
				</TransparentCard>
			</FlexDivColCentered>
		</Container>
	)
}

const TransparentCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 220px;
	padding: 0px 303px;
	box-sizing: border-box;
	text-align: center;
	border-radius: 15px;
	${media.lessThan('lgUp')`
		width: 720px;
		padding: 80px 20px;
	`}
	${media.lessThan('sm')`
		width: 345px;
	`};
`

const Container = styled.div`
	margin-bottom: 140px;
	${media.lessThan('sm')`
		margin-bottom: 105px;
	`};
`

const GrayDescription = styled(Text.Body)`
	color: ${(props) => props.theme.colors.common.secondaryGray};
	font-size: 24px;
	line-height: 120%;
	text-align: center;
	margin-top: 30px;

	${media.lessThan('sm')`
		font-size: 16px;
	`};
`

const CTAContainer = styled.div`
	margin-top: 50px;
	z-index: 1;
`

const BigWhiteHeader = styled(WhiteHeader)`
	font-size: 60px;
	width: 900px;
	${media.lessThan('sm')`
		font-size: 25px;
		width: 300px;
	`};
`

export default TradeNow
